<template>
  <div v-if="loaded" class="start-a-business">
    <progress-steps
      :subtitle="subtitle"
      :progress-status="progressStatus"
    />

    <div class="main-container">
      <h2 class="sl__h2 container-header">
        Great! <span class="company-name"> {{ company.name }} </span> has been added to your account.
      </h2>
      <div class="container-body">
        <picture>
          <source
            :srcset="require('@images/illustrations/stageline/stageline_company_creation_animation.webp')"
            type="image/webp"
          >
          <img
            :src="require('@images/illustrations/stageline/stageline_company_creation_animation.gif')"
            alt="Company Created"
            class="animation"
          >
        </picture>
      </div>
    </div>

    <button-container
      :next-button-label="nextButtonLabel"
      :show-previous-button="showPreviousButton"
      @next="next"
    />
  </div>

  <div v-else class="center-spinner">
    <ct-centered-spinner />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'CompanyCreated',
  components: {
    CtCenteredSpinner: () => import('@/components/shared/CtCenteredSpinner'),
    ProgressSteps: () => import('@/components/StagelineV2/domainsFirst/shared/ProgressSteps'),
    ButtonContainer: () => import('@/components/StagelineV2/domainsFirst/shared/ButtonContainer'),
  },
  data () {
    return {
      loaded: false,
      subtitle: 'congratulations',
      progressStatus: 100,
      nextButtonLabel: 'Continue',
      showPreviousButton: false,
    }
  },
  computed: {
    ...mapGetters('stageline', [
      'company',
    ]),
  },
  async mounted() {
    this.loaded = true
  },
  methods: {
    async next() {
      const isFormsLibrary = this.$route.params.origin === 'formsLibrary'
      isFormsLibrary ? await this.navigateToFormsLibrary() : await this.showDecisionTree()
    },
    async showDecisionTree() {
      await this.$router.push({
        name: 'stageline-v2-decision-tree',
        params: { companyId: this.company.id },
      })
    },
    async navigateToFormsLibrary() {
      await this.$router.push({ name: 'companySelection' })
    },
  },
}
</script>

<style lang="scss" scoped>
.sl__h2 {
  text-align: left;
}

.start-a-business {

  .main-container {

    .container-header {

      .company-name {
        color: $sl__violet2;
      }
    }

    .container-body {
      @include sl__flex(row, space-between, center);

      picture {

        .animation {
          width: 100%;
          max-width: 43.75em;
        }
      }
    }
  }
}
</style>
